import React from 'react'

const Contact = props => (
  <section id="contact" className="spotlights page-section">
    <div className="inner">
      <header className="major">
        <h2>Contact</h2>
      </header>
      <section>
        <form method="post" action="#">
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="c_name" />
          </div>
          <div className="field half">
            <label htmlFor="email">E-mail</label>
            <input type="text" name="email" id="c_email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="c_message" rows="6"></textarea>
          </div>
          <ul className="actions">
            <li>
              <a id="c_link" href="mailto:info@mercedesbenz300sl.be">
                <input type="button" value="Send Message" className="special" />
              </a>
            </li>
            <li>
              <input type="reset" value="Clear" />
            </li>
          </ul>
        </form>
      </section>
      {/* <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>Email</h3>
            <a href="mailt:info@mercedesbenz300sl.be">
              info@mercedesbenz300sl.be
            </a>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-building"></span>
            <h3>KBO</h3>
            <span>vzw 0889.558.096</span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-exchange"></span>
            <h3>Bank KBC</h3>
            <span>
              <b>IBAN</b>
              <br />
              BE82 7350 3445 2768
              <br />
              <b>BIC</b>
              <br />
              KREDBEBB
            </span>
          </div>
        </section>
      </section> */}
    </div>
    <script
      dangerouslySetInnerHTML={{
        __html: `
  if (!createMailtoDefined)
  {
    var createMailtoDefined = true;
    
    var CreateMailto = () => {
      let from = document.getElementById('c_email').value;
      let body = document.getElementById('c_message').value;
      let name = document.getElementById('c_name').value;
  
      let mailtoLink = \`mailto:info@mercedesbenz300sl.be?subject=Contact&body=Name:\${name}%0D%0AFrom:\${from}%0D%0ABody:\${body}\`;
  
      document.getElementById('c_link').setAttribute('href', mailtoLink);
    }
  
    document.getElementById('c_email').oninput = CreateMailto;
    document.getElementById('c_message').oninput = CreateMailto;
    document.getElementById('c_name').oninput = CreateMailto;
  }

  
`,
      }}
    />
  </section>
)

export default Contact
