import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import BoardItem from '../components/BoardItem'
import { FormattedMessage, injectIntl, Link } from 'gatsby-plugin-intl'

const BoardInfo = ({ intl }) => (
  <StaticQuery
    query={graphql`
      query {
        karel: file(relativePath: { eq: "images/karel.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        thierry: file(relativePath: { eq: "images/thierry.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        jm: file(relativePath: { eq: "images/jm.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <section id="one" className="spotlights page-section">
        <div className="inner">
          <header className="major">
            <h2>
              <FormattedMessage id="board.title" />
            </h2>
          </header>
          <BoardItem
            image={data.karel}
            title={intl.formatMessage({ id: 'board.chair' })}
            name="Karel De Kegel"
            email="info@mercedesbenz300sl.be"
          />
          <BoardItem
            image={data.thierry}
            title={intl.formatMessage({ id: 'board.vice-chair' })}
            name="Thierry Dehaeck"
            email="tdehaeck@delthi.com"
          />
          <BoardItem
            image={data.jm}
            title={intl.formatMessage({ id: 'board.secretary' })}
            name="Jean Moreau"
            email="jean.moreau@skynet.be"
          />
        </div>
      </section>
    )}
  />
)

export default injectIntl(BoardInfo)
