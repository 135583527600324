import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'

const Diverse = props => (
  <StaticQuery
    query={graphql`
      query {
        stolen: file(relativePath: { eq: "images/diverse/stolen2.JPG" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <section id="one" className="spotlights page-section">
        <div className="inner">
          <header className="major">
            <h2>
              <FormattedMessage id="diverse.title" />
            </h2>
          </header>
          <p>
            <FormattedMessage id="diverse.p1" />
          </p>
          <p>
            <FormattedMessage id="diverse.p2" />
          </p>
          <p>
            <FormattedMessage id="diverse.p3" />{' '}
            <a href="tel:+32473371148">+32&nbsp;473&nbsp;37&nbsp;11&nbsp;48</a>
          </p>
          <p>
            E-mail:{' '}
            <a href="mailto:info@ChristianeOtten.be">info@ChristianeOtten.be</a>
          </p>
          <p>
            <FormattedMessage id="diverse.p4" />
          </p>
          <div className="grid-wrapper">
            <div className="col-4">
              <section>
                <span className="image fit">
                  <Img fluid={data.stolen.childImageSharp.fluid} />
                </span>
              </section>
            </div>
            <div className="col-6">
              <div className="table-wrapper">
                <table className="alt table-diverse">
                  <tbody>
                    <tr>
                      <td className="align-center" colSpan="2">
                        <b>Mercedes-Benz 300SL Roadster 1957</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage id="diverse.table.chassis" />
                      </td>
                      <td>198 042 75 00376</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage id="diverse.table.body" />
                      </td>
                      <td>198 042 75 00390</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage id="diverse.table.motor" />
                      </td>
                      <td>198 042 75 00300</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage id="diverse.table.c_ext_key" />
                      </td>
                      <td>
                        <FormattedMessage id="diverse.table.c_ext_value" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage id="diverse.table.c_int_key" />
                      </td>
                      <td>
                        <FormattedMessage id="diverse.table.c_int_value" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage id="diverse.table.brake_key" />
                      </td>
                      <td>
                        <FormattedMessage id="diverse.table.brake_value" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  />
)

export default injectIntl(Diverse)
