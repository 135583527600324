import React from 'react'
import Img from 'gatsby-image'

const BoardItem = ({ image, title, name, email }) => (
  <section>
    <div className="grid-wrapper">
      <div className="col-4">
        <div className="image fit">
          <Img fluid={image.childImageSharp.fluid} alt={name} />
        </div>
      </div>
      <div className="col-8">
        <div className="content">
          <div className="inner">
            <header className="major">
              <h3>{title}</h3>
            </header>
            <div className="contact-method">
              <span className="icon alt fa-user"></span>
              <p>{name}</p>
            </div>
            <div className="contact-method">
              <span className="icon alt fa-envelope"></span>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BoardItem
