import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Banner from '../components/Banner'
import BoardInfo from '../components/BoardInfo'
import CompanyInfo from '../components/CompanyInfo'
import { FormattedMessage, injectIntl, Link } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
import Contact from '../components/Contact'
import Links from '../components/Links'
import FactoryPictures from '../components/PicturesSection'
import AlbumPictures from '../components/AlbumPictures'
import Diverse from '../components/Diverse'

const HomeIndex = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 123) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet
        title={intl.formatMessage({ id: 'title' })}
        htmlAttributes={{
          lang: intl.locale,
        }}
        meta={[
          {
            name: 'description',
            content: 'Mercedes-Benz 300SL Gullwing Roadster Club Belgium',
          },
          {
            name: 'keywords',
            content:
              'Mercedes-Benz 300SL Gullwing Roadster Club W198 Knokke-Heist BEHVA BFOV Karel De Kegel Thierry Dehaeck Jean Moreau',
          },
          {
            name: 'og:title',
            content: intl.formatMessage({ id: 'title' }),
          },
          {
            name: 'og:description',
            content: 'Mercedes-Benz 300SL Gullwing Roadster Club Belgium',
          },
          {
            name: 'og:url',
            content: 'https://mercedesbenz300sl.be/',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:title',
            content: intl.formatMessage({ id: 'title' }),
          },
          {
            name: 'twitter:description',
            content: 'Mercedes-Benz 300SL Gullwing Roadster Club Belgium',
          },
        ]}
      >
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://mercedesbenz300sl.be/",
            "name": "300SL Gullwing-Roadster Club Belgium",
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "info@mercedesbenz300sl.be"
            },
            "founder": {
              "@type": "Person",
              "name": "Karel De Kegel",
              "jobTitle": "Chairman"
            }
          }
        `}
        </script>
      </Helmet>

      <Banner />

      <div id="main">
        <section id="two">
          <div className="inner">
            <header className="major">
              <h2>
                <FormattedMessage id="index.header.title" />
              </h2>
            </header>
            <p>
              <Img
                className="image fit logo-max-height"
                fluid={data.logo.childImageSharp.fluid}
                alt="club logo"
              />
            </p>
            <p>
              <FormattedMessage id="index.header.p1" />
            </p>
            <p>
              <FormattedMessage id="index.header.p2" />
            </p>
            <ul className="actions">
              <li>
                <Link to="/#contact" className="button next">
                  <FormattedMessage id="index.contact" />
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <CompanyInfo />

        <BoardInfo />

        <AlbumPictures />

        <FactoryPictures />

        <Links />
        <Diverse />
      </div>
      <Contact />
    </Layout>
  )
}

export default injectIntl(HomeIndex)
