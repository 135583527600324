import React from 'react'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const FactoryPictures = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "images/factory" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <section id="one" className="spotlights page-section">
      <div className="inner">
        <header className="major">
          <h2>
            <FormattedMessage id="factory.title" />
          </h2>
        </header>
        <div className="box alt">
          <div className="grid-wrapper">
            {data.images.edges.map(({ node }) => (
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={node.childImageSharp.fluid}
                    alt="Mercedes-Benz 300SL Old Factory"
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(FactoryPictures)
