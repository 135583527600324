import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'
import React from 'react'

const Banner = ({ intl }) => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>
          <FormattedMessage id="index.banner.p1" />
          <br />
          <FormattedMessage id="title" />
        </h1>
      </header>
      <div className="content">
        <p>
          <FormattedMessage id="index.banner.p2" />
        </p>
      </div>
    </div>
  </section>
)

export default injectIntl(Banner)
