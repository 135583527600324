import React from 'react'

const CompanyInfo = props => (
  <section className="page-section">
    <div className="inner">
      <div className="grid-wrapper">
        <div className="col-4">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-envelope"></span>
              <h3>E-mail</h3>
              <a href="mailto:info@mercedesbenz300sl.be">
                info@mercedesbenz300sl.be
              </a>
            </div>
          </section>
        </div>
        <div className="col-4">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-building"></span>
              <h3>KBO</h3>
              <span>vzw 0889.558.096</span>
            </div>
          </section>
        </div>
        <div className="col-4">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-exchange"></span>
              <h3>Bank KBC</h3>
              <span>
                <b>IBAN</b>
                <br />
                BE82 7350 3445 2768
                <br />
                <b>BIC</b>
                <br />
                KREDBEBB
              </span>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
)

export default CompanyInfo
