import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'
import React from 'react'

const Links = props => (
  <section id="one" className="spotlights" className="page-section">
    <div className="inner">
      <header className="major">
        <h2>
          <FormattedMessage id="links.title" />
        </h2>
      </header>
      <h4>
        <FormattedMessage id="links.be" />
      </h4>
      <ul>
        <li>
          <a href="http://www.mercedes-benz.be">Mercedes-Benz Belgium</a>
        </li>
      </ul>
      <h4>
        <FormattedMessage id="links.ger" />
      </h4>
      <ul>
        <li>
          <a href="http://www.mercedesbenz300sl-club.de/">
            Mercedes-Benz 300 SL Club e.V.
          </a>
        </li>
        <li>
          <a href="http://www.mercedes-benz-classic.com/">
            Mercedes-Benz Classic
          </a>
        </li>
      </ul>
      <h4>
        <FormattedMessage id="links.us" />
      </h4>
      <ul>
        <li>
          <a href="http://www.gullwinggroup.org/">
            Gull Wing Group International
          </a>
        </li>
      </ul>
    </div>
  </section>
)

export default injectIntl(Links)
